/**
 * Copyright 2023 Illumio, Inc. All Rights Reserved.
 */
.link {
  display: flex;
  justify-content: end;
  margin-right: var(--18px);
  margin-bottom: var(--2px);
  color: var(--active-color);
}

.protection-poor-icon {
  color: var(--lightning--red-600);
}

.protection-good-icon {
  color: var(--lightning--orange-600);
}

.protection-excellent-icon {
  color: var(--lightning--green-600);
}

.protection-poor-pill {
  --pill-content-background-color: var(--lightning--red-50);
  --pill-content-color: var(--lightning--red-600);

  font-weight: var(--fontWeightBold);
}

.protection-good-pill {
  --pill-content-background-color: var(--lightning--orange-50);
  --pill-content-color: var(--lightning--orange-600);

  font-weight: var(--fontWeightBold);
}

.protection-excellent-pill {
  --pill-content-background-color: var(--lightning--green-50);
  --pill-content-color: var(--lightning--green-600);

  font-weight: var(--fontWeightBold);
}

.numProtectedWorkloads {
  text-align: right;
}

.ransomwareProtectionPercent {
  text-align: center;
}

.table {
  height: 85%;

  .tableRow {
    max-width: 150px;
  }
}
