/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
.container {
  :where(html[data-theme='lightning']) & {
    --map-legend-background: var(--lightning--white);
  }
}

.container {
  --legend-v-padding: var(--offset-large);
  --legend-h-padding: var(--offset-large);

  display: grid;
  grid-template-columns: 30% 70%;
  grid-gap: var(--30px);
  grid-auto-rows: auto;
  width: calc(8.35 * var(--90px));
  max-height: calc(100vh - 2 * var(--100px));
  padding: var(--30px);
  overflow: hidden;
  border-radius: var(--10px);
  box-shadow: var(--shadow-depth-4);
  background-color: var(--map-legend-background);
}

.container::before {
  position: absolute;
  content: '';
  top: var(--40px);
  left: 32%;
  width: var(--1px);
  height: 80%;
  background-color: var(--lightning--blue-gray-200); /* Color for the vertical line */
}

.leftSection,
.rightSection {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.howToRead {
  grid-column: 1/2;
  grid-row: 1;
  align-self: start;
}

.numberOfWorkloads {
  grid-column: 1/2;
  grid-row: 2;
}

.nodeTypes {
  grid-column: 2/3;
}

.nodeTypes .iconGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, auto);
  grid-gap: var(--10px);
}

.vulnerabilityOrEnforcement {
  display: flex;
  grid-column: 2/3;
  flex-direction: column;
  align-items: start;
}

.trafficLinks {
  grid-column: 2/3;
}

.denyRules {
  grid-column: 2/3;
}

.trafficLinks .iconRow {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  margin-left: calc(-3 * var(--legend-h-padding) / 2);
  gap: var(--10px);
}

.trafficLinks .iconWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.trafficLinks .iconWrapper span {
  overflow-wrap: break-word;
}

.denyRules .iconRow {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-left: calc(-3 * var(--legend-h-padding) / 2);
  gap: var(--15px);
}

.denyRules .iconWrapper {
  display: flex;
  flex-direction: column;
  align-items: center; /* Centers the content horizontally */
  text-align: center; /* Centers the text below the icon */
}

.denyRules .iconWrapper span {
  overflow-wrap: break-word;
}

.title {
  composes: bold from 'utils.css';
  grid-column: auto / span 2;
  margin-top: var(--20px);
  margin-bottom: var(--20px);
  margin-left: calc(-1 * var(--legend-h-padding) / 2);
  text-transform: uppercase;
}

.titleFullWidth {
  composes: title;
  grid-column: 1 / -1;
}

.howToRead-icon svg {
  width: var(--120px);
  height: var(--80px);
  margin-left: calc(-7 * var(--legend-h-padding) / 2);
}

.nodeSize-icon svg {
  width: var(--100px);
  height: var(--100px);
  margin-left: var(--10px);
  transform: scaleX(1.5) scaleY(1.5);
}

.workload-icon {
  grid-column: 1/2;
  grid-row: 1/2;
  width: var(--45px);
  height: var(--45px);
  margin-right: var(--3px);
}

.virtualServer-icon {
  grid-column: 2/3;
  grid-row: 1/2;
  width: var(--45px);
  height: var(--45px);
  margin-right: var(--3px);
}

.virtualService-icon {
  grid-column: 3/4;
  grid-row: 1/2;
  width: var(--45px);
  height: var(--45px);
  margin-right: var(--3px);
}

.containerWorkload-icon {
  grid-column: 1/2;
  grid-row: 2/3;
  width: var(--45px);
  height: var(--45px);
  margin-right: var(--3px);
}

.unmanaged-icon {
  grid-column: 2/3;
  grid-row: 2/3;
  width: var(--45px);
  height: var(--45px);
  margin-right: var(--3px);
}

.idle-icon {
  grid-column: 3/4;
  grid-row: 2/3;
  width: var(--45px);
  height: var(--45px);
  margin-right: var(--3px);
}

.vulnerabilityStrip {
  display: flex;
  width: 100%;
  height: var(--10px);
  margin-bottom: var(--20px);
}

.vulnerabilityStrip span > div {
  width: var(--70px);
  height: var(--10px);
  margin-bottom: var(--5px);
}

.vulnerabilityStrip p {
  margin: var(--0px);
  text-align: center;
}

.enforcementStrip {
  display: flex;
  width: 100%;
  height: var(--10px);
  margin-bottom: var(--20px);
}

.enforcementStrip span > div {
  width: var(--100px);
  height: var(--10px);
  margin-bottom: var(--5px);
}

.enforcementStrip p {
  margin: var(--0px);
  text-align: center;
}

.critical {
  background-color: var(--lightning--red-700); /* Replace with the exact color you want */
}

.high {
  background-color: var(--lightning--red-500);
}

.medium {
  background-color: var(--lightning--orange-400);
}

.low {
  background-color: var(--lightning--yellow-300);
}

.info {
  margin-right: var(--10px);
  margin-left: var(--10px);
  background-color: var(--lightning--blue-300);
}

.none {
  background-color: var(--lightning--gray-400);
}

.idle {
  border: var(--1px) dashed var(--lightning--black);
  background-color: var(--lightning--white);
}

.visibility {
  background-color: rgb(204 204 204);
}

.selective {
  background-color: rgb(99 99 99);
}

.full {
  background-color: rgb(37 37 37);
}

.containerCSframe {
  composes: container;
  column-gap: var(--gap-column-x-large);
}

.containerCSframe {
  composes: container;
  grid-template-areas:
    't2 t2 t2 t2 t2 t2'
    'g2 s2 g3 s3 g4 s4'
    'g5 s5 g6 s6 g7 s7'
    'g8 s8 g9 s9 ga sa'
    '.  .  gb sb .  .'
    '.  .  gc sc .  .';
  column-gap: var(--gap-column-x-large);
}

.lineBreak {
  grid-column: 1 / -1;
  margin: var(--3px) calc(-1 * var(--legend-h-padding));

  :where(html[data-theme='lightning']) & {
    border-bottom: 1px solid var(--lightning--gray-400);
    box-shadow: 0 1px 0 0 var(--lightning--gray-50);
  }
}

.link-icon {
  composes: centeredFlex from 'utils.css';
  width: var(--45px);
  height: var(--45px);
}

.link-svg {
  width: 150%;
  height: 100%;
  cursor: pointer;
  transform: scaleX(1) scaleY(0.5);
  transform-origin: center center;
}

.linkBlocked-icon {
  composes: link-icon;
  color: var(--map-map-link-blocked);
}

.linkBlocked-svg {
  composes: link-svg;
}

.link-deficient-Blocked-icon {
  composes: link-icon;
  color: var(--map-map-link-deficiency-blocked);
}

.link-deficient-Blocked-svg {
  composes: link-svg;
}

.linkPotentiallyBlocked-icon {
  composes: link-icon;
  color: var(--map-map-link-potentially-blocked);
}

.link-deficient-PotentiallyBlocked-icon {
  composes: link-icon;
  color: var(--map-map-link-potentially-blocked);
}

.linkPotentiallyBlocked-svg {
  composes: link-svg;
}

.link-deficient-PotentiallyBlocked-svg {
  composes: link-svg;
}

.linkNotCalculated-icon {
  composes: link-icon;
  color: var(--map-legend-link-not-calculated);
}

.linkNotCalculated-svg {
  composes: link-svg;
}

.linkAllowed-icon {
  composes: link-icon;
  color: var(--map-map-link-allowed);
}

.linkAllowed-svg {
  composes: link-svg;
}

.link-deficient-Allowed-icon {
  composes: link-icon;
  color: var(--map-map-link-deficiency-allowed);
}

.link-deficient-Allowed-svg {
  composes: link-svg;
}

.linkVulnerable-icon {
  composes: link-icon;
  color: var(--map-map-link-vulnerable);
}

.linkVulnerable-svg {
  composes: link-svg;
}

.linkPotentiallyBlockedVulnerable-icon {
  composes: link-icon;
  color: var(--map-map-link-potentiallyBlockedVulnerable);
}

.linkPotentiallyBlockedVulnerable-svg {
  composes: link-svg;
}

.linkNotVulnerable-icon {
  composes: link-icon;
  color: var(--map-map-link-notVulnerable);
}

.linkNotVulnerable-svg {
  composes: link-svg;
}

.toolBarButton {
  .button {
    padding-right: var(--0px);
    padding-left: var(--0px);
    color: var(--lightning--blue-800);
    background-color: var(--lightning--blue-50);

    .icon-icon {
      font-size: var(--16px);
    }

    &:hover {
      color: var(--lightning--blue-700);
      background-color: var(--lightning--blue-25);
    }

    &:active {
      color: var(--lightning--blue-700);
      background-color: var(--lightning--blue-100);
    }
  }
}
