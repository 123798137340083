/**
 * Copyright 2024 Illumio, Inc. All Rights Reserved.
 */

.container {
  display: flex;
  padding: var(--4px) 0;
  column-gap: var(--8px);
}

.labelsWrapper {
  flex-grow: 1;
  min-width: calc(var(--100px) * 1.4);
}

.labelsSelector-emptyMessage-plain .labelsSelector-emptyMessage-main .labelsSelector-emptyMessage-core {
  font-size: var(--16px);
}
