/**
 * Copyright 2024 Illumio, Inc. All Rights Reserved.
 */

.container {
  padding: var(--4px) var(--0px);
}

.expressionWrapper {
  display: flex;
  flex-direction: column;
  gap: var(--4px);
}
