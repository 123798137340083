/**
 * Copyright 2024 Illumio, Inc. All Rights Reserved.
 */
.option {
  margin: var(--5px) 0;
  padding: var(--2px);
  font-size: var(--13px);
  line-height: var(--16px);
  cursor: pointer;
  transition: background-color 0.3s;
}

.activeOption {
  background-color: var(--lightning--blue-50); /* Active background color */
}

.selector-footer {
  justify-content: flex-end;
  padding: var(--10px);
}

.selector-input {
  margin-left: var(--offset-small);
}

.selector-label {
  position: relative;
  z-index: 1;
  width: fit-content;
  margin-bottom: calc(-1 * var(--32px));
  padding-right: var(--0px);
}

.selector-resources {
  /* stylelint-disable-next-line declaration-no-important */
  --resources-template-wide-screen: repeat(auto-fit, minmax(0, 1fr)) !important;
  /* stylelint-disable-next-line declaration-no-important */
  --resources-template-small-screen: repeat(auto-fit, minmax(0, 1fr)) !important;

  display: grid;
  grid-template-columns: var(--resources-template-wide-screen);
  overflow: unset;
}

.selector-resources :not(:last-child) {
  grid-column: auto / span 3;
}

.selector-resources :last-child {
  grid-column: auto / span 5;
  overflow-y: auto;
}

.selector-title {
  padding: var(--5px) 0;
  font-size: var(--12px);
  color: var(--lightning--blue-gray-700);
  text-transform: uppercase;
}

.selector-infoPanel {
  padding: var(--10px);
  font-size: var(--10px);
  font-weight: var(--fontWeightBold);
  line-height: var(--12px);
  color: var(--lightning--blue-gray-700);
  text-transform: uppercase;
  background-color: var(--lightning--blue-25);

  > div {
    vertical-align: middle;
    line-height: var(--10px);
  }
}

.selector-optionPanel {
  padding: 0;
}

.selector-resources > div:not(:last-child) {
  border-right: var(--1px) solid var(--lightning--blue-gray-400);
}

.selector-resourceSubtitle {
  margin: 0;
}

.selector-searchBarContainer {
  --searchBar-background-color: var(--lightning--blue-600);
  --searchBar-border-color: transparent;
  --searchBar-border-color-focused: transparent;
  --searchBar-background-color-focused: var(--lightning--blue-600);

  height: var(--30px);
}

.selector-listResource {
  padding: var(--10px);
  > li:has(.activeOption) {
    border-radius: var(--4px);
    background-color: var(--lightning--blue-50);
  }

  .selector-option {
    &.selector-highlighted {
      border-radius: var(--4px);
      background-color: var(--lightning--blue-50);
    }
  }
}

.selector-dropdown {
  min-width: calc(10 * var(--40px));
}

.selector-dropdown-tooltip .selector-dropdown-content {
  --dropdown-border-radius: var(--8px);
}

.selector-disabled {
  /* stylelint-disable-next-line declaration-no-important */
  --searchBar-background-color: var(--lightning--blue-100) !important;
}

.rule-illustration-image {
  display: block;
  width: calc(20 * var(--10px));
  margin-right: auto;
  margin-left: auto;
}

.rule-illustration-title {
  margin-bottom: var(--8px);
  font-size: var(--14px);
  font-weight: var(--fontWeightSemiBold);
  line-height: var(--18px);
  color: var(--lightning--blue-gray-800);
}

.rule-illustration-desc {
  max-width: calc(30 * var(--10px));
  margin-bottom: var(--36px);
  font-size: var(--12px);
  font-weight: var(--fontWeight);
  line-height: var(--15px);
  color: var(--lightning--blue-gray-800);
}
