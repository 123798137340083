/**
 * Copyright 2024 Illumio, Inc. All Rights Reserved.
 */
.enforcementToggle {
  display: flex;
  align-items: center;
  gap: var(--8px);

  .icon {
    width: var(--16px);
    height: var(--16px);
    color: var(--lightning--blue-600);
  }
}

.scoreLabel {
  font-size: var(--16px);
  color: var(--lightning--blue-gray-700);
}

.processesListGrid-gridContainer {
  position: relative;
  flex-grow: 1;
  margin: 0 var(--3px);
}

.processesListGrid-rowBody {
  .processesListGrid-areaBody {
    :where(html[data-theme='lightning']) & {
      border-bottom: var(--1px) solid var(--lightning--blue-gray-200);
    }
  }
}
