/**
 * Copyright 2023 Illumio, Inc. All Rights Reserved.
 */
.container {
  margin: var(--12px);
}

.widgetContainer {
  display: flex;
  width: 100%;
  height: 300px;
  gap: var(--12px);
}

.widget {
  width: 33%;
}

.notification {
  margin-bottom: var(--20px);
}

.spaceBetween {
  margin-bottom: var(--20px);
}

.blueText {
  font-weight: var(--fontWeightBold);
  color: var(--lightning--blue-800);
}

.action {
  display: grid;
  grid-template-columns: 2fr 1fr;
  margin-bottom: var(--20px);
}

.actionTitle {
  font-size: var(--13px);
  font-weight: var(--fontWeightBold);
}

.actionSubtitle {
  font-size: var(--11px);
  font-weight: var(--fontWeightSemiBold);
}

.text {
  display: flex;
  flex-direction: column;
}

.reviewTrafficActionButton-button {
  font-size: var(--12px);
}

.cardAlignTop {
  justify-content: flex-start;
  margin-top: var(--28px);
}
