/**
 * Copyright 2023 Illumio, Inc. All Rights Reserved.
 */

.container {
  flex-grow: 1;
  padding-right: var(--20px);
  padding-left: var(--20px);

  :where(html[data-theme='lightning']) & {
    padding-right: var(--0px);
    padding-left: var(--0px);
  }
}

.rowHead .cell {
  display: flex;
  flex-grow: 1;
  align-items: center;
}

.toolBar {
  padding: var(--8px) var(--0px);
}

.enforcementToggle {
  display: flex;
  align-items: center;
  gap: var(--8px);

  .icon {
    width: var(--16px);
    height: var(--16px);
    color: var(--lightning--blue-600);
  }
}

.scoreLabel {
  font-size: var(--16px);
  color: var(--lightning--blue-gray-700);
}
