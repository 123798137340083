/**
 * Copyright 2024 Illumio, Inc. All Rights Reserved.
 */

.container {
  flex-grow: 1;
  row-gap: var(--8px);
}

.sidePanel-sidePanelContent {
  display: flex;
  flex-direction: column;
}

.sidePanel-sidePanel .sidePanel-sidePanelContent .sidePanel-sidePanelHeader .sidePanel-sidePanelHeaderText {
  margin-inline: var(--0px);
}

.gridContainer {
  position: relative;
  flex-grow: 1;
}

.gridContainerScrollable {
  position: absolute;
  inset: 0;
  overflow-y: scroll;
}

.notifications-message {
  width: 100%;
}

.assignLabelsSuccessWrapper {
  display: inline-flex;
  width: 100%;
}

.assignLabelsSuccessMessage {
  flex-grow: 1;
  align-self: flex-start;
}

.assignLabelsSuccessBoldText {
  padding-right: var(--8px);
  font-weight: var(--fontWeightBold);
}

.applyRulesLoadingContainer {
  display: grid;
  grid-row: span 2;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto auto;
  grid-gap: var(--0px) var(--8px);
}

.applyRulesSpinner-spinner {
  grid-area: 1/1/3/2;
  color: var(--lightning--blue-800);
}

.applyRulesLoadingHeader {
  grid-area: 1/2/2/3;
  font-weight: var(--fontWeightBold);
  color: var(--lightning--blue-900);
}

.applyRulesLoadingMessage {
  grid-area: 2/2/3/3;
  font-size: var(--13px);
  color: var(--text-color);
}
