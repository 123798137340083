/**
 * Copyright 2024 Illumio, Inc. All Rights Reserved.
 */
.sidePanel {
  /* NOTE: !important needed to override re-resizable position:relative */
  position: fixed !important; /* stylelint-disable-line declaration-no-important */
  display: flex;
  z-index: var(--shadow-above-all-z);
  inset-block: 0;
  inset-inline-end: 0;
  padding: var(--30px);
  overflow-y: scroll;
  box-shadow: var(--shadow-depth-3);
  background: var(--sidepanel-background-color);

  .sidePanelContent {
    width: 100%;

    .brandIcon {
      width: var(--40px);
      height: var(--40px);
      border: var(--1px) solid var(--sidepanel-brand-icon-border-color);
      border-radius: var(--6px);
      background-color: var(--sidepanel-background-color);
    }

    .sidePanelHeader {
      display: flex;
      justify-content: space-between;
      margin-block-end: var(--20px);

      .sidePanelHeaderMainContent {
        display: flex;
        align-items: center;
      }

      .sidePanelHeaderTitle {
        margin-block: calc(-1 * var(--2px)) var(--4px);
        font-size: var(--16px);
        font-weight: var(--fontWeightSemiBold);
        color: var(--sidepanel-header-title-color);
      }

      .sidePanelHeaderSubtitle {
        font-size: var(--13px);
        color: var(--sidepanel-header-subtitle-color);
      }
    }

    .tabPanel {
      margin-block-end: var(--20px);
    }
  }

  :where(html[data-theme='lightning']) & {
    --sidepanel-background-color: rgba(var(--lightning--white-rgb));
    --sidepanel-header-title-color: var(--lightning--blue-gray-900);
    --sidepanel-header-subtitle-color: var(--lightning--blue-gray-500);
    --sidepanel-brand-icon-border-color: var(--lightning--blue-gray-400);
  }

  :where(html[data-theme='dark']) & {
    --sidepanel-background-color: rgba(var(--dark--black-rgb));
    --sidepanel-header-title-color: var(--dark--blue-gray-900);
    --sidepanel-header-subtitle-color: var(--dark--blue-gray-500);
    --sidepanel-brand-icon-border-color: var(--dark--blue-gray-400);
  }
}
