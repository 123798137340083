/**
 * Copyright 2023 Illumio, Inc. All Rights Reserved.
 */

.membersContainer {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  padding: var(--15px) var(--10px) var(--10px) var(--10px);
  gap: var(--10px);
}
