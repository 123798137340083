/**
 * Copyright 2024 Illumio, Inc. All Rights Reserved.
 */

.container {
  flex-grow: 1;
  margin: 0 var(--5px);
}

.labelRulesGrid-gridContainer {
  position: relative;
  flex-grow: 1;
  margin: 0 var(--3px);
}

.labelRulesGrid-rowBody {
  .labelRulesGrid-areaBody {
    :where(html[data-theme='lightning']) & {
      border-bottom: var(--1px) solid var(--lightning--blue-gray-200);
    }
  }
}

.toolBar {
  padding: var(--8px) var(--0px);
}

.toolGroup {
  align-items: center;
}

.arrowContainer {
  padding: var(--8px) var(--0px);
}

.ruleNumberWrapper {
  padding-top: var(--4px);
}

.ruleStatusWrapper {
  display: flex;
  padding-top: var(--4px);
  column-gap: var(--4px);
}

.ruleStatusIcon-icon,
.ruleStatusText {
  color: var(--lightning--green-600);
}

.ruleStatusWrapper.disabled .ruleStatusIcon-icon,
.ruleStatusWrapper.disabled .ruleStatusText {
  color: var(--lightning--blue-gray-600);
}

.modifiedBy-link {
  word-break: break-word;
}

.scheduleDescriptionContainer {
  display: flex;
  align-items: center;
  column-gap: var(--4px);
}

.scheduleEditLink {
  color: var(--lightning--blue-600);

  &:hover {
    text-decoration: underline;
  }
}

.notifications-notifications .notifications-close-button {
  color: var(--text-color);
}
