/**
 * Copyright 2024 Illumio, Inc. All Rights Reserved.
 */

.notifications-notifications {
  position: fixed;
  z-index: calc(var(--shadow-above-all-z) + 1);
  right: var(--20px);
  bottom: var(--20px);
  width: calc(var(--100px) * 1.8 * 1.5); /* 180px * 1.5 = 270px */
}

.notifications-success,
.notifications-error {
  box-shadow: var(--shadow-depth-4);
}

.notifications-notifications .notifications-close-button {
  color: var(--text-color);
}

.link {
  composes: link from 'components/Link/Link.css';
}
