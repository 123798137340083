/**
 * Copyright 2023 Illumio, Inc. All Rights Reserved.
 */
.table {
  width: 100%;

  .tableHeader {
    font-size: var(--11px);
    font-weight: var(--fontWeightBold);
    color: var(--lightning--blue-gray-500);
    text-transform: uppercase;
  }
  .tableBody {
    vertical-align: top;
    border-top: var(--8px) solid transparent;
  }
  .tableRow {
    max-width: 0;
    font-size: var(--14px);
    font-weight: var(--fontWeightSemiBold);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
