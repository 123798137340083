/**
 * Copyright 2023 Illumio, Inc. All Rights Reserved.
 */

.container {
  flex-grow: 1;
}

.totalContainer {
  display: flex;
  align-items: center;
  margin: var(--10px) var(--10px) var(--10px) var(--0px);
  padding: var(--10px);
  background-color: var(--lightning--gray-50);
}

.totalLabel {
  margin-right: var(--6px);
  font-size: var(--16px);
  color: var(--lightning--blue-gray-600);
}

.totalValue {
  font-size: var(--22px);
  font-weight: var(--fontWeightBold);
  color: var(--lightning--blue-800);
}

.enforcementToggle {
  display: flex;
  align-items: center;
  margin: var(--10px) var(--10px) var(--10px) var(--0px);
  padding: var(--10px);
  gap: var(--8px);

  .icon {
    width: var(--16px);
    height: var(--16px);
    color: var(--lightning--blue-600);
  }
}

.scoreLabel {
  font-size: var(--16px);
  color: var(--lightning--blue-gray-700);
}

.appGroupVulnerabilitiesGrid-gridContainer {
  position: relative;
  flex-grow: 1;
  margin: 0 var(--3px);
}

.appGroupVulnerabilitiesGrid-rowBody {
  .appGroupVulnerabilitiesGrid-areaBody {
    :where(html[data-theme='lightning']) & {
      border-bottom: var(--1px) solid var(--lightning--blue-gray-200);
    }
  }
}
