/**
 * Copyright 2024 Illumio, Inc. All Rights Reserved.
 */

.score {
  position: relative;
  display: flex;
  align-items: baseline;
  width: max-content;
  padding: var(--8px) var(--6px);
  font-size: var(--16px);
  font-weight: 600; /* stylelint-disable-line */
  line-height: var(--24px);
  text-align: center;

  .signal {
    display: inline-flex;
    justify-content: flex-end;
    align-items: flex-end;
    height: var(--30px);
    padding: var(--8px) var(--4px);

    span {
      display: inline-block;
      width: var(--3px);
      margin-left: var(--2px);
      border-radius: 2px;
      background-color: var(--lightning--gray-400);
    }

    .bar1 {
      height: 25%;
    }
    .bar2 {
      height: 50%;
    }
    .bar3 {
      height: 75%;
    }
    .bar4 {
      height: 100%;
    }
  }

  &:hover {
    border-radius: var(--4px);
    background-color: var(--lightning--blue-gray-100);
  }
}

/* Vulnerability types */
.critical {
  .signal {
    .bar1,
    .bar2,
    .bar3,
    .bar4 {
      background-color: var(--lightning--red-700);
    }
  }

  .severityIcon {
    background-color: var(--lightning--red-700);
  }
}

.high {
  .signal {
    .bar1,
    .bar2,
    .bar3 {
      background-color: var(--lightning--red-500);
    }
  }

  .severityIcon {
    background-color: var(--lightning--red-500);
  }
}

.medium {
  .signal {
    .bar1,
    .bar2 {
      background-color: var(--lightning--orange-400);
    }
  }

  .severityIcon {
    background-color: var(--lightning--orange-400);
  }
}

.low {
  .signal {
    .bar1 {
      background-color: var(--lightning--yellow-300);
    }
  }

  .severityIcon {
    background-color: var(--lightning--yellow-300);
  }
}

.syncing-icon {
  font-size: var(--16px);
}

.clickable {
  cursor: pointer;

  &:focus {
    outline: none;
  }
}

.severityStatus {
  display: inline-flex;
  align-items: center;

  .severityIcon {
    display: inline-block;
    width: var(--10px);
    height: var(--10px);
    margin-right: var(--4px);
    margin-left: var(--2px);
    border-radius: 50%;
  }
}
