/**
 * Copyright 2024 Illumio, Inc. All Rights Reserved.
 */

.frequencyContainer {
  margin-top: var(--10px);
}

.weeklyOptionsContainer {
  display: flex;
  justify-content: flex-start;
  margin-top: var(--4px);
  column-gap: var(--4px);
}

.weekdayButton {
  padding: var(--8px) var(--14px);
  cursor: pointer;
  border-radius: var(--6px);
  background-color: var(--lightning--blue-gray-50);
}

.selected {
  color: var(--lightning--blue-700);
  background-color: var(--lightning--blue-50);
}

.monthlyOptionsContainer {
  margin-top: var(--4px);
}

.monthFirstLastSelector-selectorMain,
.monthDaySelector-selectorMain {
  width: var(--88px);
}

.optionDropdown {
  overflow: scroll;
}

.monthlyOption {
  display: flex;
  align-items: center;
}

.monthFirstLastSuffix {
  margin-left: var(--8px);
}

.Radio_labelText {
  padding-right: var(--0px);
}
