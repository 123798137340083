/**
 * Copyright 2023 Illumio, Inc. All Rights Reserved.
 */

.allowed {
  color: var(--map-map-link-allowed);
}

.blocked {
  color: var(--map-map-link-blocked);
}

.potentiallyBlocked {
  color: var(--map-map-link-potentially-blocked);
}

.unknown {
  color: var(--lightning--gray-400);
}

.totalContainer {
  display: flex;
  align-items: center;
  padding: var(--10px);
  background-color: var(--lightning--gray-50);
}

.totalLabel {
  margin-right: var(--6px);
  font-size: var(--16px);
  color: var(--lightning--blue-gray-600);
}

.totalValue {
  font-size: var(--22px);
  font-weight: var(--fontWeightBold);
  color: var(--lightning--blue-800);
}

.toggleEnforcement {
  display: flex;
  align-items: center;
  gap: var(--8px);
}

.scoreLabel {
  font-size: var(--16px);
  color: var(--lightning--blue-gray-700);
}

.totalVEScore {
  font-weight: var(--fontWeightLight);
}

.icon {
  width: var(--16px);
  height: var(--16px);
  color: var(--lightning--blue-600);
}

.vulnerabilitiesListGrid-gridContainer {
  position: relative;
  flex-grow: 1;
  margin: 0 var(--3px);
}

.vulnerabilitiesListGrid-rowBody {
  .vulnerabilitiesListGrid-areaBody {
    :where(html[data-theme='lightning']) & {
      border-bottom: var(--1px) solid var(--lightning--blue-gray-200);
    }
  }
}
