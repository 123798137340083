/**
 * Copyright 2024 Illumio, Inc. All Rights Reserved.
 */

:root {
  --label-rules-operator-color: rgb(91 63 220);
  --label-rules-logic-operator-color: var(--lightning--blue-gray-600);
  --label-rules-expression-pill-line-count: 1;
}

/* ----- begin expression pills ----- */
.conditionAttribute,
.conditionOperator,
.conditionLogicOperator,
.conditionValue,
.conditionValueRemoveButton {
  display: inline-block;
}

.conditionWrapper {
  display: flex;
  align-items: center;
  gap: var(--4px);
}

.conditionAttribute {
  font-weight: var(--fontWeight);
  text-wrap: nowrap;
}

.conditionOperator,
.conditionLogicOperator {
  margin: var(--0px) var(--4px);
  font-weight: var(--fontWeight);
  color: var(--label-rules-operator-color);
  text-wrap: nowrap;
}

.expressionLogicOperator {
  font-weight: var(--fontWeight);
  color: var(--label-rules-logic-operator-color);
  text-wrap: nowrap;
}

.conditionPill-pill,
.conditionPill-content {
  border-radius: var(--4px);
}

.conditionPill-pill.conditionPill-clickable:not(:active):hover,
.conditionPill-pill.conditionPill-clickable:not(:active):focus-visible {
  --pill-content-color: var(--pill-content-color-hover);
  --pill-content-background-color: var(--pill-content-background-color-hover);
  --pill-content-border-color: var(--pill-content-border-color-hover);
}

.conditionPill-pill.conditionPill-clickable:active:hover,
.conditionPill-pill.conditionPill-clickable:active:focus-visible {
  --pill-content-color: var(--pill-content-color-active);
  --pill-content-background-color: var(--pill-content-background-color-active);
  --pill-content-border-color: var(--pill-content-border-color-active);
}

.conditionPill-content {
  padding: var(--pill-content-vertical-padding) calc(2 * var(--pill-content-horizontal-padding))
    calc(1.05 * var(--pill-content-vertical-padding)) calc(2 * var(--pill-content-horizontal-padding));
  line-height: calc(1.15 * var(--pill-line-height));
}

.conditionPill-text {
  --pill-content-border-width: var(--1px);
  --pill-content-border-color: transparent;
  --pill-content-outline-width: var(--0px);
  --pill-text-horizontal-padding: var(--0px);
  --pill-content-vertical-padding: var(--0px);

  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
}

/* remove ellipsis and 2 line maximum from pill */
.conditionPill-text {
  text-overflow: unset;
  overflow: unset;
  -webkit-line-clamp: unset;
  line-clamp: unset;
}

.conditionValue {
  display: inline-flex;
  align-items: center;
  font-weight: var(--fontWeight);
  gap: var(--0px) var(--2px);
}

/* add ellipsis to the condition value after max number of lines */
.conditionValue.conditionValueTruncated {
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  line-clamp: var(--label-rules-expression-pill-line-count);

  @supports (-webkit-line-clamp: var(--label-rules-expression-pill-line-count)) {
    /* stylelint-disable-next-line value-no-vendor-prefix */
    display: -webkit-inline-box;
    white-space: initial;
    -webkit-line-clamp: var(--label-rules-expression-pill-line-count);
    -webkit-box-orient: vertical;
  }
}

.conditionValueRemoveButton:hover {
  --pill-close-icon-color: var(--pill-close-icon-color-hover);
  --pill-close-background-color: var(--pill-close-background-color-hover);
}

.conditionValueRemoveButton {
  display: inline-flex;
  align-self: center;
  margin-block: calc(-1 * var(--pill-content-vertical-padding));
  margin-inline: calc(-1 * var(--pill-content-horizontal-padding));
  padding-block: var(--pill-content-vertical-padding);
  padding-inline: var(--pill-content-horizontal-padding);
  font-size: var(--13px);
  color: var(--pill-close-icon-color);
  cursor: pointer;
  outline: inherit;
  border: none;
  background: none;
}

.conditionValueRemoveIcon-icon {
  display: inline-flex;
  align-items: center;
  font-size: var(--13px);
  color: var(--pill-close-icon-color);
  border-radius: var(--20px);
  background-color: var(--pill-close-background-color);
}

.conditionValueRemoveIcon-icon .conditionValueRemoveIcon-svg {
  display: inline-block;
  width: 1em;
  height: 1em;
  transform: scale(0.6);
  fill: currentcolor;
}
/* ----- end expression pills ----- */
