/**
 * Copyright 2024 Illumio, Inc. All Rights Reserved.
 */

.container {
  display: flex;
  flex-wrap: wrap;
  padding: var(--4px) var(--0px);
  gap: var(--4px);
}
