/**
 * Copyright 2024 Illumio, Inc. All Rights Reserved.
 */
.listSideBySide {
  > .row {
    > .key {
      align-self: flex-start;
    }
  }
}
