/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */

.toolbarPanel {
  position: absolute;
  bottom: var(--10px);
  left: var(--18px);
  width: var(--40px);
}

.toolBarButton {
  .button {
    color: var(--lightning--blue-800);
    background-color: var(--lightning--blue-50);

    .icon-icon {
      font-size: var(--16px);
    }

    &:hover {
      color: var(--lightning--blue-700);
      background-color: var(--lightning--blue-25);
    }

    &:active {
      color: var(--lightning--blue-700);
      background-color: var(--lightning--blue-100);
    }
  }

  > .menu-menuMedium {
    flex-grow: 1;

    > .button {
      flex-grow: 1;
      background-color: var(--lightning--blue-50);
    }
  }
}

.sortByDropdown {
  /* stylelint-disable-next-line declaration-no-important -- !important needed to override inline style when menu is in 'up' orientation */
  margin-top: calc(-1 * var(--54px)) !important;
  margin-left: var(--50px);
}
