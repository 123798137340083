/**
 * Copyright 2024 Illumio, Inc. All Rights Reserved.
 */
.container {
  padding: var(--4px) var(--0px);
}

.expressionWrapper {
  display: flex;
  flex-direction: column;
  gap: var(--4px);
}

.conditionWrapper {
  display: flex;
  align-items: center;
}

.logicOperator {
  align-self: center;
  font-weight: var(--fontWeight);
  color: var(--label-rules-logic-operator-color);
  text-wrap: nowrap;
}

/* ----- begin LabelRulesListExpressionPills overrides ----- */
.conditionAttribute {
  padding-left: var(--4px);
}

.conditionPill-content {
  padding: calc(0.7 * var(--pill-content-vertical-padding)) calc(1 * var(--pill-content-horizontal-padding))
    calc(1.05 * var(--pill-content-vertical-padding)) calc(1.25 * var(--pill-content-horizontal-padding));
  line-height: var(--pill-line-height);
}

.attributeOperatorWrapper .conditionPill-text {
  line-height: calc(1.02 * var(--18px));
}
/* ----- end LabelRulesListExpressionPills overrides ----- */

.option {
  padding: var(--5px) var(--5px) var(--5px) var(--10px);
  border-radius: var(--4px);
}

.selectedOption {
  background-color: var(--lightning--blue-100);

  &:hover {
    background-color: var(--lightning--blue-200);
  }
}

/* ----- begin selector overrides ----- */
.expressionSelector-option {
  padding: var(--0px);
}

.expressionSelector-option.expressionSelector-highlighted,
.expressionSelector-option.expressionSelector-highlighted .selectedOption {
  background-color: var(--lightning--blue-200);
}

.expressionSelector-dropdown-tooltip {
  width: 70vw;
}

.expressionSelector-resources {
  grid-template: auto / minmax(max-content, calc(var(--100px) * 1.8)) minmax(max-content, calc(var(--100px) * 1.8)) minmax(
      calc(var(--100px) * 3),
      1fr
    );
}

/* stack vertically for small screens so the user has enough room in the text input */
@media (width <= 600px) {
  .expressionSelector-resources {
    grid-template: auto / minmax(calc(var(--100px) * 1.8), 1fr);
  }
}

.expressionSelector-optionPanel {
  padding: var(--0px);
}

.expressionSelector-resources > div {
  border-right: 1px solid var(--lightning--gray-300);
}

.expressionSelector-resources > div:nth-of-type(4) {
  border-right: none;
}

.expressionSelector-option-checkbox > div > label {
  padding-top: var(--4px);
  padding-left: var(--8px);
}

.expressionSelector-infoPanel {
  display: flex;
  align-items: center;
  padding: var(--6px) var(--8px);
  font-weight: var(--fontWeightBold);
  color: var(--lightning--gray-800);
  text-transform: uppercase;
  background-color: var(--lightning--gray-100);
}

.expressionSelector-items {
  align-items: center;
  gap: 0;
}

.expressionSelector-input[readonly]:hover {
  cursor: default;
}

.expressionSelector-listResource {
  padding: var(--8px);
}

.expressionSelector-containerResource {
  padding: var(--0px);
}

.expressionSelector-inputPanel {
  max-height: var(--optionSelector-line-height);
}

/* hide selector input when dropdown is closed and has selected values */
.hideInput .expressionSelector-inputPanel {
  max-height: var(--0px);
}

/* hide selector input when dropdown is open */
.expressionSelector-dropdown-tooltip .expressionSelector-inputPanel.expressionSelector-show {
  position: absolute; /* needed for attribute/operator kb-nav to work */
  max-height: var(--0px);
}

.expressionSelector-dropdown-top .expressionSelector-inputPanel.expressionSelector-show {
  bottom: 0; /* needed for attribute/operator kb-nav to work in 'top' placement */
}

.expressionSelector-valuePanel {
  flex-grow: 1;
  flex-basis: 100%;
}

.expressionSelector-selectedResource {
  flex-grow: 1;
}

.expressionSelector-searchBarContainer {
  align-items: center;
}

.expressionSelector-searchBarContainer > button {
  align-self: stretch;
}
/* ----- end selector overrides ----- */

/* ----- begin expression selector footer ----- */

.expressionSelectorFooter {
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
  padding: var(--6px);
}

/* ----- end expression selector footer ----- */

/* ----- begin LabelRulesListEditExpressionContainer styles ----- */
.containerContainer {
  padding-bottom: var(--8px);
}

.containerInfoPanel {
  width: 100%;
  padding: var(--6px) var(--8px);
  font-weight: var(--fontWeightBold);
  color: var(--lightning--gray-800);
  text-transform: uppercase;
  background-color: var(--lightning--gray-100);
}

.containerContent {
  display: flex;
  flex-direction: column;
  margin-top: var(--8px);
  padding: var(--0px) var(--8px);
  row-gap: var(--8px);
}

.containerOption {
  cursor: pointer;
}

.containerHighlightedOption {
  background-color: var(--lightning--blue-200);
}

.containerInput-input:hover::placeholder {
  color: var(--input-placeholder-color);
}
/* ----- end LabelRulesListEditExpressionContainer styles ----- */
