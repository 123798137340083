/**
 * Copyright 2023 Illumio, Inc. All Rights Reserved.
 */

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.container {
  flex-grow: 1;
  margin: 0 var(--5px);
}

.gridContainer {
  position: relative;
  flex-grow: 1;
  margin: 0 var(--3px);
}

.gridContainerScrollable {
  position: absolute;
  inset: 0;
  overflow-x: hidden;
  overflow-y: scroll;
}

.table-minWidth_640 {
  .areaBody {
    padding: var(--8px) 0;
  }
}

.toolBar {
  padding: var(--8px);
}

.name {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.banner {
  margin-top: var(--100px);
}

.protection-poor-pill {
  --pill-content-background-color: var(--lightning--red-50);
  --pill-content-color: var(--lightning--red-600);

  font-weight: var(--fontWeightBold);
}

.protection-good-pill {
  --pill-content-background-color: var(--lightning--orange-50);
  --pill-content-color: var(--lightning--orange-600);

  font-weight: var(--fontWeightBold);
}

.protection-excellent-pill {
  --pill-content-background-color: var(--lightning--green-50);
  --pill-content-color: var(--lightning--green-600);

  font-weight: var(--fontWeightBold);
}

.coverageContainer {
  display: flex;
  align-items: center;
  column-gap: var(--4px);
}

.coverageRecalculationButton {
  padding: var(--0px) var(--4px);
  font-size: 1.25em;

  &:hover {
    color: var(--lightning--gray-700);
  }

  &.disabled {
    color: var(--lightning--gray-600);
  }
}

.coverageRecalculationIconSpinning {
  transform: translateZ(0);
  animation: rotate 1.1s infinite linear;
}

.coverageLink {
  display: block;
  flex-grow: 1;
}

@keyframes coverageLoadingGradientAnimation {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -200% 0;
  }
}

.coverageLoadingBar {
  width: 100%;
  height: var(--12px);
  border-radius: var(--2px);
  background: repeating-linear-gradient(
    to right,
    var(--lightning--gray-350) 0%,
    var(--lightning--gray-400) 25%,
    var(--lightning--gray-500) 50%,
    var(--lightning--gray-400) 75%,
    var(--lightning--gray-350) 100%
  );
  background-position: 0 100%;
  background-size: 200% auto;
  animation-name: coverageLoadingGradientAnimation;
  animation-duration: 5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-fill-mode: forwards;
}

@keyframes coverageRecalculationErrorAnimation {
  0% {
    color: var(--lightning--gray-800);
  }
  20% {
    color: var(--lightning--red-500);
  }
  80% {
    color: var(--lightning--red-500);
  }
  100% {
    color: var(--lightning--gray-800);
  }
}

.coverageRecalculationIconError {
  animation-name: coverageRecalculationErrorAnimation;
  animation-duration: 0.8s;
  animation-timing-function: var(--ease-in-out-quad);
}

.coverageBarTooltip {
  --tooltip-color: var(--lightning--white);
  --tooltip-bg-color: var(--lightning--blue-gray-900);

  composes: tooltip from 'components/Tooltip/Tooltip.css';
  box-shadow: var(--lightning--shadow-400);
}

.coverageBarTooltipContent {
  composes: content from 'components/Tooltip/Tooltip.css';
}

.enforcementToggle {
  display: flex;
  align-items: center;
  gap: var(--8px);

  .icon {
    width: var(--16px);
    height: var(--16px);
    color: var(--lightning--blue-600);
  }
}

.scoreLabel {
  font-size: var(--16px);
  color: var(--lightning--blue-gray-700);
}
