/**
 * Copyright 2024 Illumio, Inc. All Rights Reserved.
 */

:root {
  --map-super-app-group-search-box-border-radius: var(--8px);
  --map-super-app-group-search-box-padding-h: var(--12px);
  --map-super-app-group-search-box-padding-v: var(--4px);
  --map-super-app-group-search-box-background-color: var(--lightning--white);
  --map-super-app-group-search-box-border-color: var(--lightning--blue-gray-400);
  --map-super-app-group-search-box-border-color-focused: var(--lightning--blue-500);
  --map-super-app-group-search-box-suggestion-color: var(--lightning--gray-500);
}

.container {
  position: relative;
  height: var(--32px);
  border: 1px solid var(--map-super-app-group-search-box-border-color);
  border-radius: var(--map-super-app-group-search-box-border-radius);
  background-color: var(--map-super-app-group-search-box-background-color);

  &.focused {
    border-color: var(--map-super-app-group-search-box-border-color-focused);
  }
}

.input {
  position: relative;
  width: 100%;
  height: 100%;
  padding: var(--map-super-app-group-search-box-padding-v) var(--map-super-app-group-search-box-padding-h);
  color: var(--text-color);
  outline: none;
  border: none;
  background-color: transparent;
}

.suggestion {
  composes: input;
  position: absolute;
  color: var(--map-super-app-group-search-box-suggestion-color);
}
