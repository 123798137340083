/**
 * Copyright 2024 Illumio, Inc. All Rights Reserved.
 */
.fieldLabel {
  font-size: var(--15px);
  font-weight: var(--fontWeightBold);
  color: var(--lightning--blue-800);
  text-transform: uppercase;
  border-radius: var(--2px);
}

.fieldContainer {
  display: flex;
  align-items: center;

  .option {
    padding-left: var(--20px);
    font-size: var(--14px);
    color: var(--lightning--blue-gray-800);
  }
}

.frequencySelector-selectorMain {
  width: calc(var(--100px) + var(--60px));
}

.dateAndTimeContainer {
  composes: gapSmall from 'utils.css';
}

.dateTimeLabel {
  font-size: var(--13px);
  font-weight: var(--fontWeightBold);
  color: var(--lightning--blue-gray-600);
}
