/**
 * Copyright 2024 Illumio, Inc. All Rights Reserved.
 */

.container {
  /* the shadow DOM elements do not cause the input's width to expand, so we need to provide a width */
  --time-picker-hm-input-width: calc(var(--100px) * 1.21); /* format is hh:mm */
  --time-picker-hms-input-width: calc(var(--100px) * 1.5); /* format is hh:mm:ss */
  --time-picker-hm-noicon-input-width: calc(var(--100px) * 0.98); /* format is hh:mm, and no icon */
  --time-picker-hms-noicon-input-width: calc(var(--100px) * 1.26); /* format is hh:mm:ss, and no icon */
}

/* BEGIN time input */
.input-input {
  padding: var(--4px) var(--3px) var(--4px) var(--5px);
  font-size: var(--14px);
  font-weight: var(--fontWeightSemiBold);
  color: var(--lightning--blue-700);
  cursor: pointer;
  border-color: var(--lightning--blue-50);
  background-color: var(--lightning--blue-50);
}

/* hh:mm format */
:not(.withSeconds) .input-input {
  width: var(--time-picker-hm-input-width);
}

/* hh:mm:ss format */
.withSeconds .input-input {
  width: var(--time-picker-hms-input-width);
}

.input-input:focus {
  border-color: var(--lightning--blue-200);
  background-color: var(--lightning--blue-50);
}
/* END time input */

/*
----------------------------------------------------
Native Time Input Shadow DOM Layout Diagram:
----------------------------------------------------

The native HTML time input's editable hour, minute, second, and am/pm fields (a.k.a. spinners)
exist in the input's shadow DOM. They are essentially rendered inside the input element. In
chrome, they are composed as pseudo elements; in firefox, they're elements. Below is a map of
the parts of the native HTML time input. In some browsers (chrome and edge), there is also a
dropdown time picker. At this time, only the input itself can be styled. It does not appear
that the dropdown time picker is customizable in any browser.

--------------------------------------------------------------------------------
Chrome Diagram:
                 6      7      8
                 ^      ^      ^
      +----------|------|------|--------------+
11 <--| +--------|------|------|--------+     |
      | | +------|------|------|------+ |     |
10 <----| |      |      |      |      | |     |
      | | | [00] : [00] : [00]   [AM] | | [I] |
 9 <------|   |      |      |      |  | |  |  |
      | | +---|------|------|------|--+ |  |  |
      | +-----|------|------|------|----+  |  |
      +-------|------|------|------|-------|--+
              v      v      v      v       v
              1      2      3      4       5

 #  Field           Pseudo-element selectors               Notes:
 -  -----           ------------------------               ------
 1. hour            ::-webkit-datetime-edit-hour-field
 2. minute          ::-webkit-datetime-edit-minute-field
 3. second          ::-webkit-datetime-edit-second-field   (not present in hh:mm mode)
 4. am/pm           ::-webkit-datetime-edit-ampm-field     (not present in 24hr mode)
 5. clock icon      ::-webkit-calendar-picker-indicator
 6. colon           ::-webkit-datetime-edit-text
 7. colon           ::-webkit-datetime-edit-text           (not present in hh:mm mode)
 8. space           ::-webkit-datetime-edit-text
 9. fields wrapper  ::-webkit-datetime-edit-fields-wrapper
10. edit wrapper    ::-webkit-datetime-edit
11. container       ::-internal-datetime-container

To inspect the input's shadow DOM in Chrome's developer tools, open Chrome
DevTools, go to Preferences > Elements, and check "Show user agent shadow DOM".

--------------------------------------------------------------------------------
Firefox Diagram:
                6      7      8
                ^      ^      ^
      +---------|------|------|-------------+
      |  +------|------|------|------+      |
10 <--|  |      |      |      |      |      |
      |  | [00] : [00] : [00]   [AM] |  [I] |
 9 <-----|   |      |      |      |  |   |  |
      |  +---|------|------|------|--+   |  |
      +------|------|------|------|------|--+
             v      v      v      v      v
             1      2      3      4      5

 #  Field          Element selectors:                                                  Notes:
 -  -----          ------------------                                                  ------
 1. hour           .datetime-edit-field.numeric OR [data-l10n-id="datetime-hour"]
 2. minute         .datetime-edit-field.numeric OR [data-l10n-id="datetime-minute"]
 3. second         .datetime-edit-field.numeric OR [data-l10n-id="datetime-second"]    (not present in hh:mm mode)
 4. am/pm          .datetime-edit-field         OR [data-l10n-id="datetime-dayperiod"] (not present in 24hr mode)
 5. calendar icon  .datetime-calendar-button    OR [data-l10n-id="datetime-calendar"]  (hidden for input[type="time"])
 6. colon          span
 7. colon          span                                                                 (not present in hh:mm mode)
 8. space          span
 9. edit wrapper   .datetime-input-edit-wrapper OR #edit-wrapper
10. container      .datetime-input-box-wrapper  OR #input-box-wrapper

To inspect the shadow DOM in Firefox, go to about:config, and turn on the following settings:
'devtools.inspector.showUserAgentStyles' and 'devtools.inspector.showAllAnonymousContent'

NOTE: In Firefox, the elements are in the shadow DOM, which is in 'closed' mode. So, it does not appear possible to use
these selectors to apply custom styles to the component in Firefox. So far, we've only been able to apply styles to the
input itself, not the actual elements in the shadow DOM. These notes are just here as a reference in case we find a way
to style the input's shadow DOM in FF.

--------------------------------------------------------------------------------
*/

/* ----- BEGIN Webkit Styles ----- */

/* wrapper around hour, min, sec, and am/pm */
.input-input::-webkit-datetime-edit-fields-wrapper {
  display: flex;
}

/* hour, minute, second, and am/pm */
.input-input::-webkit-datetime-edit-hour-field,
.input-input::-webkit-datetime-edit-minute-field,
.input-input::-webkit-datetime-edit-second-field,
.input-input::-webkit-datetime-edit-ampm-field {
  display: block;
  flex: 0 0 var(--18px); /* flex-basis here specifies the width of hour, minute, second, ampm fields */
  padding: var(--2px) var(--3px);
  text-align: center;
  border-radius: var(--4px);

  &:focus {
    background-color: var(--lightning--blue-500); /* bg-color when editing a field */
  }
}

/* colons between hour/min and min/sec, and space between min/sec and am/pm */
.input-input::-webkit-datetime-edit-text {
  display: block;
}

.input-input::-webkit-datetime-edit-ampm-field {
  margin-left: calc(-1 * var(--3px)); /* pull am/pm to the left to reduce space created by -webkit-datetime-edit-text */
}

/* clock icon used to show time picker */
.input-input::-webkit-calendar-picker-indicator {
  display: block;
  block-size: 1.3em; /* increase clock dimensions */
  inline-size: 1.3em; /* increase clock dimensions */
  margin-top: calc(-1 * var(--1px)); /* fix vertical alignment */
  margin-inline-start: var(--2px); /* reduce left margin */
  cursor: pointer;
  filter: invert(0.4) sepia(0.3) saturate(10) hue-rotate(175deg); /* make clock blue */
}

/* ----- END Webkit Styles ----- */

/* ----- BEGIN Firefox Styles ----- */
@supports (-moz-appearance: none) {
  .container:not(.withSeconds) .input-input {
    width: var(--time-picker-hm-noicon-input-width); /* calendar icon hidden for input[type=time] in FF */
  }

  .container.withSeconds .input-input {
    width: var(--time-picker-hms-noicon-input-width); /* calendar icon hidden for input[type=time] in FF */
  }
}
/* ----- END Firefox Styles ----- */
